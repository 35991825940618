import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DeleteForever, VisibilityOutlined, Logout } from "@mui/icons-material";
import { toast } from "react-toastify";

import Sidebar from "../../../sidebar/Sidebar";
import { server } from "../../../../server";
import axios from "axios";
import Loader from "../../loader/Loader";

const Borrowers = ({ setAuth }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);

  const [filteredClients, setFilteredClients] = useState([]);
  const [user, setUser] = useState();

  console.log("filteredClients ...", filteredClients);

  const email = user?.email;

  useEffect(() => {
    const fetchBorrowerByToken = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }

        const response = await fetch(`${server}/user/get-user`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();

        setUser(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching user:", error);
      }
    };

    fetchBorrowerByToken();
  }, []);

  useEffect(() => {
    const fetchBorrowers = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }
        const response = await fetch(`${server}/borrower/get-borrowers`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        const sortedBorrowers = data.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setLoading(false);
        setFilteredClients(sortedBorrowers);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching borrowers:", error);
      }
      setLoading(false);
    };

    fetchBorrowers();
  }, []);
  useEffect(() => {
    const delaySearch = setTimeout(async () => {
      setLoading(true);

      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }
        const response = await fetch(`${server}/borrower/get-borrowers`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setLoading(false);

        const data = await response.json();
        const sortedBorrowers = data.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        if (searchTerm === "") {
          setFilteredClients(sortedBorrowers);
        } else {
          const lowerCaseSearchTerm = searchTerm.toLowerCase();
          const filteredData = sortedBorrowers.filter((client) =>
            client.idno.toLowerCase().includes(lowerCaseSearchTerm)
          );
          setFilteredClients(filteredData);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching borrowers:", error);
        toast.error("Error fetching borrowers. Login Again");
      }
    }, 300); // Adjust the delay time as needed (e.g., 300 milliseconds)

    return () => clearTimeout(delaySearch);
  }, [searchTerm]);

  const handleDelete = async (id) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this borrower?. \n PLEASE NOTE: All Loans And Payments Associated With This Borrower Will Be Deletes As Well. "
    );

    if (isConfirmed) {
      const loadingToastId = toast.info("Deleting borrower...", {
        autoClose: false,
      });
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }

        await axios.delete(`${server}/borrower/delete-borrower/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        toast.dismiss(loadingToastId);

        try {
          const response = await fetch(`${server}/borrower/get-borrowers`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const data = await response.json();
          const sortedBorrowers = data.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
          setFilteredClients(sortedBorrowers);
          toast.success("Borrower deleted successfully");
        } catch (error) {
          toast.dismiss(loadingToastId);
          console.error("Error fetching borrowers:", error);
          toast.error("Error fetching borrowers");
        }
      } catch (error) {
        toast.dismiss(loadingToastId);
        console.error("Error deleting borrower:", error);
        if (error.response.data.error) {
          toast.error(error.response.data.error);
        } else {
          toast.error("Internal Server Error");
        }
      }
    }
  };

  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await axios.post(`${server}/user/logout`, { email });
      localStorage.removeItem("token");
      toast.success("Logout Success!");
      navigate("/login");
      setAuth(false);
    } catch (error) {
      console.log(error);
      toast.error("Error Occured!");
    }
  };

  return (
    <div className="text-gray-900 h-[900px] flex">
      <Sidebar />
      {/* Clients */}
      <div className="w-full h-[900px] mx-auto px-8 py-8 mb-4 border bg-white shadow-md rounded ">
        {/* HEADER */}
        <div className="flex items-center justify-between px-4 py-5 sm:px-6 bg-red-500 rounded shadow-md ">
          <div>
            <h3 className="text-lg font-medium leading-6 text-white">
              Borrowers
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-white">
              All clients registered
            </p>
          </div>

          {/* BUTTON */}

          <div className="text-white">
            <button
              className=""
              onClick={(e) => {
                setAuth(false);
              }}
            >
              <button className="" onClick={handleLogout}>
                <Logout />
              </button>
            </button>
          </div>
        </div>

        {/* TITLE */}
        <div className="flex items-center justify-between border-y-2 mt-5">
          <h3 className="text-lg font-medium leading-6 text-gray my-2  px-1 py-2 ">
            Borrowers' List
          </h3>
          <div className="w-2/3">
            <label
              for="default-search"
              class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
            >
              Search
            </label>
            <div class="relative">
              <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  class="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="search"
                id="default-search"
                class="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50   dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
                placeholder="Search Borrower by ID Number..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                required
              />
              <button
                type="submit"
                class="text-white absolute end-2.5 bottom-2.5 bg-red-500 hover:bg-red-700 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-4 py-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-blue-800"
              >
                Search
              </button>
            </div>
          </div>

          <button className="border hover:bg-red-700 bg-red-500 text-white font-bold py-2 px-4 mb-2 rounded focus:outline-none focus:shadow-outline w-auto mt-2 mr-5">
            <Link to="/addBorrower">Add Borrower</Link>
          </button>
        </div>

        {/* INFO */}
        <div className="w-full h-[640px] px-4   mt-5 overflow-auto hover:overflow-scroll border rounded shadow-md border-t-4 border-t-red-500 ">
          {loading ? (
            <Loader />
          ) : (
            <table className="table-fixed text-center mb-2">
              <thead className=" mt-5">
                <tr className=" mt-10">
                  <th className="w-1/1 px-1 py-2 text-gray-600">No.</th>
                  <th className="w-1/5 px-1 py-2 text-gray-600">Full Name</th>
                  <th className="w-1/5 px-1 py-2 text-gray-600">
                    Contact Number
                  </th>
                  <th className="w-1/5 px-4 py-2 text-gray-600">ID No.</th>
                  <th className="w-1/5 px-1 py-2 text-gray-600">Email</th>
                  <th className="w-1/5 px-4 py-2 text-gray-600">Created By</th>
                  <th className="w-1/1 px-1 py-2 text-gray-600">Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredClients.length <= 0 ? (
                  <tr className="border px-4 py-2 bg-red-50">
                    <td></td>
                    <td></td>
                    <td className="px-4 py-2 bg-red-50">No Client Data</td>
                    <td></td>
                    <td></td>
                  </tr>
                ) : (
                  filteredClients.map((client, index) => {
                    return user?.isSeller === true ? (
                      client.createrId === user.userId && (
                        <tr key={index}>
                          <td className="border px-4 py-2 bg-gray-50">
                            {client._id.replace(/\D/g, "")}
                          </td>

                          <td className="border px-4 py-2 ">
                            {client.firstname + " " + client.lastname}{" "}
                          </td>
                          <td className="border px-4 py-2 bg-gray-50">
                            {client.phone}
                          </td>
                          <td className="border px-4 py-2">{client.idno}</td>
                          <td className="border px-4 py-2 bg-gray-50">
                            {client.email}
                          </td>
                          <td className="border px-4 py-2 bg-gray-50">
                            {client.createdBy}
                          </td>
                          <td className="border px-4 py-2">
                            <button
                              onClick={() => handleDelete(client._id)}
                              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 mb-2 rounded focus:outline-none focus:shadow-outline w-full text-sm"
                            >
                              <DeleteForever className="text-lg" />
                            </button>
                            <Link to={`/Borrower/${client._id}`}>
                              <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full ">
                                <VisibilityOutlined className="text-sm" />
                              </button>
                            </Link>
                          </td>
                        </tr>
                      )
                    ) : (
                      <tr key={index}>
                        <td className="border px-4 py-2 bg-gray-50">
                          {client._id.replace(/\D/g, "")}
                        </td>

                        <td className="border px-4 py-2 ">
                          {client.firstname + " " + client.lastname}{" "}
                        </td>
                        <td className="border px-4 py-2 bg-gray-50">
                          {client.phone}
                        </td>
                        <td className="border px-4 py-2">{client.idno}</td>
                        <td className="border px-4 py-2 bg-gray-50">
                          {client.email}
                        </td>
                        <td className="border px-4 py-2 bg-gray-50">
                          {client.createdBy}
                        </td>
                        <td className="border px-4 py-2">
                          <button
                            onClick={() => handleDelete(client._id)}
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 mb-2 rounded focus:outline-none focus:shadow-outline w-full text-sm"
                          >
                            <DeleteForever className="text-lg" />
                          </button>
                          <Link to={`/Borrower/${client._id}`}>
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full ">
                              <VisibilityOutlined className="text-sm" />
                            </button>
                          </Link>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default Borrowers;
